import React, { useEffect, useMemo, useState } from "react";
import {
  AdditionalCustomerData,
  ColumnDef,
  OrderItemsType,
  OrderSheet,
  PricelistVersion,
  PricelistSettings
} from "./../types/order-sheet";
import { ORDER_BASE_URI } from "./../constants/s3";
import axios from "axios";

import LoadingSpinner from "./LoadingSpinner";
import SubmitOrder from "./ProductTable/Modal/SubmitOrder";
import ProductTable from "./ProductTable/ProductTable";
import { toast, ToastContainer, TypeOptions } from "react-toastify";
import { useParams } from "react-router-dom";
import Header from "./Header";
import getColumns from "./ProductTable/ColumnDef";
import {useCompany} from "../context/CompanyContext";

function ProductTableContainer() {
  const [orderItems, setOrderItems] = useState<OrderItemsType | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const [orderSheetData, setOrderSheetData] = useState<{
    isfetchingOrdersheet: boolean;
    error: string | null;
    columns: ColumnDef[];
    ordersheet: OrderSheet;
    orderItems: OrderItemsType;
    pricelistVersion: PricelistVersion,
  }>({
    isfetchingOrdersheet: true,
    error: null,
    columns: [],
    ordersheet: {} as OrderSheet,
    orderItems: {} as OrderItemsType,
    pricelistVersion: {} as PricelistVersion,
  });

  const {selectedCompany} = useCompany();

  let { orderSheetToken } = useParams();

  const fetchData = useMemo(() => {
    return async () => {
      try {
        const {
          data: apiResponse,
        }: {
          data: {
            orderSheet: OrderSheet;
            orderItems: OrderItemsType;
            pricelistSettings: PricelistSettings;
            pricelistVersion: PricelistVersion;
          };
        } = await axios.get(
          /*"http://localhost:3000" +*/ ORDER_BASE_URI +
            "/token/" +
            orderSheetToken
        );

        const columns = getColumns(
          apiResponse.orderSheet.exportOptions.currency,
          apiResponse.pricelistSettings.vatRatePercent,
          apiResponse.orderSheet.currencyInfo.baseCurrency,
          apiResponse.orderSheet.products.some(p => p.variants.some(v => v.customerProductCode !== null)),
          apiResponse.orderSheet.exportOptions.discount ?? null,
          selectedCompany
        );

        setOrderSheetData({
          isfetchingOrdersheet: false,
          columns,
          error: null,
          ordersheet: apiResponse.orderSheet,
          orderItems: apiResponse.orderItems,
          pricelistVersion: apiResponse.pricelistVersion,
        });
      } catch (error: any) {
        let message =
          `We couldn't find your order-sheet, or something went wrong. Please consult ${selectedCompany.email}`;
        if (error?.response?.status === 412) {
          message =
            `Maximum number of orders reached, please contact ${selectedCompany.email}`;
        }
        setOrderSheetData({
          ...orderSheetData,
          isfetchingOrdersheet: false,
          error: message,
        });
      }
    };
  }, [selectedCompany]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const downloadOrdersheet = async (orderItems: OrderItemsType) => {
    let type, message;
    try {
      const response = await axios.post(
          `${ORDER_BASE_URI}/download`,
          {
            token: orderSheetToken,
            orderItems,
            customerType: orderSheetData.ordersheet.exportOptions.customerType,
          },
          {
            responseType: "arraybuffer", // Ensure binary response
          }
      );

      // Create a Blob for the Excel file
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Create a temporary link to trigger the download
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);

      // Use the filename from the response or a default value
      const filename =
          response.headers["content-disposition"]?.match(/filename="(.+)"/)?.[1] ||
          "OrderSheet.xlsx";

      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Success message
      message = `Downloaded File: ${filename} successfully.`;
      type = "success";
    } catch (error: any) {
      // Error handling
      if (error?.response?.status === 412) {
        message =
            "Maximum number of orders reached, please contact sales@geske.com";
      } else {
        message = "Something went wrong.";
      }
      type = "error";
    }

    // Show toast notification
    toast(message, {
      autoClose: 3000,
      position: "bottom-right",
      type: type as TypeOptions,
    });
  };

  const sendMail = async (orderItems: OrderItemsType, form: AdditionalCustomerData) => {
    let type, message;

    try {
      const res = await axios.post(ORDER_BASE_URI + "/mail", {
        token: orderSheetToken,
        orderItems,
        customerType: orderSheetData.ordersheet.exportOptions.customerType, // seems unused?
        ...form,
      });

      message =
        res.status !== 200 ? "Something went wrong." : "Sent successfully.";
      type = res.status !== 200 ? "error" : "success";
    } catch (error: any) {
      if (error?.response?.status === 412) {
        message =
          "Maximum number of orders reached, please contact sales@geske.com";
      } else {
        message = "Something went wrong.";
      }
      type = "error";
    }

    toast(message, {
      autoClose: 3000,
      position: "bottom-right",
      type: type as TypeOptions,
    });
  };

  const saveDraft = async (orderItems: OrderItemsType) => {
    let type, message;

    try {
      const res = await axios.post(
        `${ORDER_BASE_URI}/${orderSheetToken}/draft`,
        {
          orderItems,
        }
      );

      message =
        res.status !== 200
          ? "Something went wrong."
          : "Draft Saved successfully.";
      type = res.status !== 200 ? "error" : "success";
    } catch (error: any) {
      message = "Something went wrong.";
      type = "error";
    }

    toast(message, {
      autoClose: 3000,
      position: "bottom-right",
      type: type as TypeOptions,
    });
  };

  const onClose = () => {
    if (loading) return;
    setOrderItems(null);
  }
  const onOpen = (orderItems: OrderItemsType) => {
    if (loading) return;

    if(Object.keys(orderItems).length === 0) {
      toast("There is no product in the order, please add one.", {
        autoClose: 3000,
        position: "bottom-right",
        type: "error" as TypeOptions,
      });
      return;
    };

    setOrderItems(orderItems);
  }

  const onSubmitOrder = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (loading) return;

    const formData = new FormData(event.currentTarget);
    const formJson = Object.fromEntries((formData as FormData).entries());
    setLoading(true);
    await sendMail(orderItems as OrderItemsType, formJson as AdditionalCustomerData);
    setLoading(false);
    setOrderItems(null);
  };

  if (orderSheetData.isfetchingOrdersheet) {
    return <LoadingSpinner />;
  }
  if (orderSheetData.error) {
    return (
      <div className="error">
        <h3>{orderSheetData.error}</h3>
      </div>
    );
  }

  return (
    <>
      <Header
        leftContent={
          <>
            <h1>Order Sheet</h1>
            <div className="headline__text__date">
              {[
                orderSheetData.ordersheet?.exportOptions?.countryCode ?? orderSheetData.ordersheet?.exportOptions?.pricelist?.shorthand ?? orderSheetData.ordersheet?.exportOptions?.pricelist?.name,
                orderSheetData.pricelistVersion?.id ? `v${orderSheetData.pricelistVersion.id}` : '',
                orderSheetData.pricelistVersion?.publishedAt.split('T')[0]
              ].filter(el => !!el).join(', ')}
            </div>
          </>
        }
      />
      <ProductTable
        columns={orderSheetData.columns}
        orderSheetDataFromAPI={orderSheetData.ordersheet}
        orderItemsInitial={orderSheetData.orderItems}
        handleDownloadOrdersheet={downloadOrdersheet}
        handleSendMail={onOpen}
        handleSaveDraft={saveDraft}
      />
      <ToastContainer />
      <SubmitOrder
        onClose={onClose}
        open={orderItems !== null}
        onSubmit={onSubmitOrder}
        loading={loading}
      />
    </>
  );
}

export default ProductTableContainer;
