import { Box, Button, Menu, MenuItem } from "@mui/material";
import {FiltersType, ProductForTable} from "../../types/order-sheet";
import { useRef, useState } from "react";

const MuiClass = {
  button: {
    margin: '5px',
    padding: '10px 15px',
    fontWeight: 'bold',
    textTransform: 'none',
    fontFamily: 'inherit',
    lineHeight: '1.15',
    backgroundColor: '#fff',
    border: '2px solid #fff',
    transition: 'transform 0.1s ease',
    '&:hover': {
      backgroundColor: '#fff',
      border: '2px solid #fff',
    },
  },
  menuItem: {
    fontFamily: 'inherit',
  }
}

const TableActionHeaders = ({
  setFilters,
  filters,
  setSortedField,
  disableButtons,
  sendMail,
  downloadOrdersheet,
  resetForm,
  saveDraft,
}: {
  setFilters: React.Dispatch<React.SetStateAction<FiltersType>>;
  filters: {
    text: string;
    select: {};
  };
  setSortedField: React.Dispatch<
    React.SetStateAction<{
      key: keyof ProductForTable;
      direction: "asc" | "desc";
    } | null>
  >;
  disableButtons: boolean;
  sendMail: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  downloadOrdersheet: (e: React.MouseEvent<HTMLLIElement, MouseEvent>, withAllData: boolean) => void;
  resetForm: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  saveDraft: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}) => {
  const [showMenu, setShowMenu] = useState<{
    state: boolean;
    ref: null | any;
  }>({
    state: false,
    ref: useRef()
  });

  return (
    <div className="actions">
      <div className="actions__left actions__container">
        <input
          className="actions__input_field"
          type="text"
          value={filters.text}
          placeholder="Enter search keyword"
          onChange={(e) =>
            setFilters({ ...filters, text: e.currentTarget.value })
          }
        />
        <button
          className="actions__action"
          onClick={() => {
            setSortedField(null);
            setFilters({
              text: "",
              select: {
              },
            });
          }}
        >
          Reset Filters
        </button>
      </div>
      <div className="actions__right actions__container">
        <button
          className={`actions__action${disableButtons ? " -loading" : ""}`}
          disabled={disableButtons}
          onClick={(e) => saveDraft(e)}
        >
          Save Draft
        </button>
        <button
          className={`actions__action${disableButtons ? " -loading" : ""}`}
          disabled={disableButtons}
          onClick={(e) => sendMail(e)}
        >
          Submit Order
        </button>
        <Box ref={showMenu.ref}>
        <Button
        className="actions__action"
                sx={MuiClass.button}
                variant="text"
                color="inherit"
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                  setShowMenu({state: true, ref: showMenu.ref})
                }}
                >
                 Export Excel
              </Button>
              {showMenu.ref.current && (
                <Menu
                  anchorEl={showMenu.ref.current}
                  open={showMenu.state}
                  onClose={() => setShowMenu({state: false, ref: showMenu.ref})}
                >
                  <MenuItem sx={MuiClass.menuItem} onClick={(e) => downloadOrdersheet(e, false)}>
                    Exclude Empty Rows
                  </MenuItem>
                  <MenuItem sx={MuiClass.menuItem} onClick={(e) => downloadOrdersheet(e, true)}>
                    Include Empty Rows
                  </MenuItem>
                </Menu>
      )}
      </Box>
        <button
          disabled={disableButtons}
          className="actions__action"
          onClick={(e) => resetForm(e)}
        >
          Clear Inputs
        </button>
      </div>
    </div>
  );
};

export default TableActionHeaders;
